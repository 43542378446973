<template>
    <div>
        <el-form :inline="true" size="mini" :model="queryParams" class="demo-form-inline">
            <el-form-item label="地区">
                <el-input v-model="queryParams.area" clearable></el-input>
            </el-form-item>
            <el-form-item label="分组">
                <el-select v-model="queryParams.groupId" clearable>
                    <el-option v-for="item in groupList" :key="item.id" :label="item.groupingName"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="IP类型">
                <el-select v-model="queryParams.ipType" clearable>
                    <el-option key="international" label="国际" value="international"></el-option>
                    <el-option key="china" label="国内" value="china"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="IP状态">
                <el-select v-model="queryParams.ipStatus" clearable>
                    <el-option v-for="(label, value) in getAllIpStatus()" :key="value" :label="label"
                               :value="value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="loading" @click="getList">查询</el-button>
            </el-form-item>
        </el-form>

        <div>
            <el-button type="primary" size="mini" @click="ipGroupShow=true">分组管理</el-button>
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="ipGroupSelectShow=true">
                批量设置分组
            </el-button>
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="upgradeBandwidthShow=true">
                批量升级带宽
            </el-button>
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="productSelectShow=true">
                批量切换项目
            </el-button>
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="ipGroupShow=true">
                批量自动续费
            </el-button>
            <el-button type="primary" :disabled="selectedIps.length<=0" size="mini" @click="renewShow=true">批量续费
            </el-button>
        </div>

        <el-table
            v-loading="tableLoading"
            :data="list"
            border
            stripe
            max-height="800px"
            @selection-change="handleSelectionChange"
            style="width: 100%;margin-top: 20px">
            <el-table-column
                type="selection"
                align="center"
                fixed
                width="55">
            </el-table-column>
            <el-table-column
                prop="groupName"
                align="center"
                label="分组">
            </el-table-column>
            <el-table-column
                prop="IP"
                width="260px"
                align="left"
                label="IP">
                <template slot-scope="scope">
                    <div> IP：{{ scope.row.ip }}</div>
                    <div>HTTP：{{ scope.row.httpPort }}</div>
                    <div>STOCK5：{{ scope.row.stock5Port }}</div>
                    <div>账号：{{ scope.row.accountNo }}</div>
                    <div>密码：{{ scope.row.pssword }}</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="ipStatus"
                width="120px"
                align="center"
                label="IP状态">
                <template slot-scope="scope">
                    {{ ipStatusFormat(scope.row.ipStatus) }}
                </template>
            </el-table-column>
            <el-table-column
                prop="ipType"
                align="left"
                width="200px"
                label="IP类型">
                <template slot-scope="scope">
                    <div v-for="item in scope.row.options">
                        {{ ipTypeOptionFormat(item.skuCode) }}：{{ item.optionName }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="area"
                width="110px"
                align="center"
                label="地区">
            </el-table-column>
            <el-table-column
                prop="expireTime"
                width="200px"
                align="center"
                label="创建/到期时间">
                <template slot-scope="scope">
                    <div> {{ scope.row.createTime }}</div>
                    <div> {{ scope.row.expireTime }}</div>
                    <div>{{ scope.row.autoRenew ? '自动续费' : '' }}</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="switchProjectNum"
                width="180px"
                align="center"
                label="项目剩余可切换次数">
            </el-table-column>
            <el-table-column
                prop="switchOperatorNum"
                width="180px"
                align="center"
                label="运营商剩余可切换次数">
            </el-table-column>
        </el-table>
        <div style="text-align: center;margin: 20px">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="changePage"
                :total="total">
            </el-pagination>
        </div>

        <el-dialog
            title="ip分组"
            :visible.sync="ipGroupShow"
            width="60%">
            <div>
                <IpGroup v-if="ipGroupShow"></IpGroup>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ipGroupShow = false">取 消</el-button>
                <el-button type="primary" @click="ipGroupShow = false">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="ip分组选择"
            :visible.sync="ipGroupSelectShow"
            width="30%">
            <div>
                <el-form>
                    <el-form-item label="分组选择">
                        <el-select v-model="selectedGroupId" clearable style="width: 100%">
                            <el-option v-for="item in groupList" :key="item.id" :label="item.groupingName"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ipGroupSelectShow = false">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitSelectGroup">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="项目选择"
            :visible.sync="productSelectShow"
            width="30%">
            <div>
                <el-form>
                    <el-form-item label="项目选择">
                        <el-select v-model="selectedProductId" clearable style="width: 100%">
                            <el-option v-for="item in productList" :key="item.id" :label="item.optionName"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="productSelectShow = false">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitSelectProduct">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            destroy-on-close
            title="续费"
            :visible.sync="renewShow"
            width="30%">
            <div>
                <TradeType ref="tradeType" @close="renewShow=false" @pay="doRenew" @paySuccess="paySuccess">
                    <el-form>
                        <el-form-item label="选择周期">
                            <el-select v-model="renewForm.selectedCycleId" clearable>
                                <el-option v-for="item in cycleList" :key="item.id" :label="item.optionName"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择数量">
                            <el-input-number v-model="renewForm.num" :min="1"></el-input-number>
                        </el-form-item>
                    </el-form>
                </TradeType>
            </div>
        </el-dialog>

        <el-dialog
            destroy-on-close
            title="带宽升级"
            :visible.sync="upgradeBandwidthShow"
            width="30%">
            <div>
                <TradeType v-if="upgradeBandwidthShow" ref="tradeTypeUpgradeBandwidth"
                           @close="upgradeBandwidthShow=false" @pay="doUpgradeBandwidth"
                           @paySuccess="paySuccess">
                    <el-form>
                        <el-form-item label="选择带宽">
                            <el-select v-model="upgradeBandwidthForm.selectedOptionId" clearable>
                                <el-option v-for="item in upgradeBandwidthList" :key="item.id" :label="item.optionName"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </TradeType>
            </div>
        </el-dialog>

    </div>
</template>
<script>

import {addDateRange, getAllIpStatus, ipStatusFormat, ipTypeOptionFormat, tansParams,} from "@/api/utils";
import IpGroup from "@/components/IpGroup.vue";
import TradeType from "@/components/TradeTypeSelect.vue";

export default {
    name: 'IpList',
    data() {
        return {
            loading: false,
            tableLoading: false,
            renewShow: false,
            ipGroupShow: false,
            ipGroupSelectShow: false,
            upgradeBandwidthShow: false,
            selectedGroupId: undefined,
            selectedProductId: undefined,
            productSelectShow: undefined,
            upgradeBandwidthForm: {
                selectedOptionId: undefined,
                num: 1,
            },
            renewForm: {
                selectedCycleId: undefined,
                num: 1,
            },
            timeRange: [],
            cycleList: [],
            list: [],
            selectedIps: [],
            groupList: [],
            productList: [],
            upgradeBandwidthList: [],
            total: 0,
            queryParams: {
                pageSize: 10,
                pageNum: 1,
                groupId: '',
                ipType: '',
                area: '',
                ipStatus: '',
                packageId: '',
            }
        };
    },
    components: {TradeType, IpGroup},
    created() {
        console.log('accountInfo create...')
        this.getList()
        this.getGroupList()
    },
    watch: {
        renewShow: function (val) {
            if (val) {
                this.getAllCycle()
            }
        },
        productSelectShow: function (val) {
            if (val) {
                this.getAllProduct()
            }
        },
        upgradeBandwidthShow: function (val) {
            if (val) {
                this.getUpgradeBandwidthList()
            }
        },
    },
    beforeDestroy() {
        console.log('accountInfo beforeDestroy...')
    },
    destroyed() {
        console.log('accountInfo destroyed...')
    },
    methods: {
        getAllIpStatus,
        ipTypeOptionFormat,
        ipStatusFormat,
        handleSelectionChange(val) {
            this.selectedIps = val
        },
        doUpgradeBandwidth(tradeType) {
            this.$http.post('/feiyu/ip/upgradeBandwidth', {
                idList: this.selectedIps.map(item => item.id),
                tradeType: tradeType,
                optionId: this.upgradeBandwidthForm.selectedOptionId,
            }).then(res => {
                this.$message.success('下单成功,请及时支付')
                this.$refs.tradeTypeUpgradeBandwidth.generateQRCode(res.data.codeUrl)
                this.$refs.tradeTypeUpgradeBandwidth.amt = res.data.totalPrice
                this.$refs.tradeTypeUpgradeBandwidth.loading = false
                this.$refs.tradeTypeUpgradeBandwidth.startInterval(res.data.orderId)
            }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.$refs.tradeTypeUpgradeBandwidth.loading = false
            })
        },
        doRenew(tradeType) {
            this.loading = true
            this.$http.post('/feiyu/ip/IpRenew', {
                ids: this.selectedIps.map(item => item.id),
                tradeType: tradeType,
                cycleOptionId: this.renewForm.selectedCycleId,
                cycleNum: this.renewForm.num,
            }).then(res => {
                this.$message.success('下单成功,请及时支付')
                this.$refs.tradeType.generateQRCode(res.data.codeUrl)
                this.$refs.tradeType.amt = res.data.totalPrice
                this.$refs.tradeType.loading = false
                this.$refs.tradeType.startInterval(res.data.orderId)
            }).finally(() => {
                this.loading = false
            })
        },
        getUpgradeBandwidthList() {
            this.$http.get('/feiyu/ip/queryUpgradeBandwidthList').then(res => {
                this.upgradeBandwidthList = res.data
            })
        },
        paySuccess() {
            console.log('pay success')
            this.$message.success('支付成功')
            this.getList()
            this.renewShow = false
        },
        getAllCycle() {
            this.$http.get('/feiyu/goods/getAllCycle').then(res => {
                this.cycleList = res.data
            })
        },
        submitSelectGroup() {
            if (!this.selectedGroupId) {
                this.$message.error('请选择分组')
                return
            }
            this.loading = true
            this.$http.post('/feiyu/ipGrouping/batchGrouping', {
                ids: this.selectedIps.map(item => item.id),
                groupingId: this.selectedGroupId
            }).then(res => {
                this.$message.success('修改成功')
                this.ipGroupSelectShow = false
                this.getList()
            }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        getGroupList() {
            this.$http.get('/feiyu/ipGrouping/list?pageNum=' + 100)
                .then(res => {
                    this.groupList = res.rows
                }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        changePage(page) {
            this.queryParams.pageNum = page
            this.getList()
        },
        getAllProduct() {
            this.$http.get('/feiyu/goods/getAllProject')
                .then(res => {
                    this.productList = res.data
                }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        submitSelectProduct() {
            if (!this.selectedProductId) {
                this.$message.error('请选择产品')
                return
            }
            this.loading = true
            this.$http.post('/feiyu/ip/switchGames', {
                ipIdList: this.selectedIps.map(item => item.id),
                projectId: this.selectedProductId
            }).then(res => {
                this.$alert(`切换成功${res.data.isSuccessNum}次，失败${res.data.failNum}次`, '操作成功', {
                    confirmButtonText: '确定',
                });
                this.productSelectShow = false
                this.getList()
            }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.loading = false
            })
        },
        getList() {
            this.tableLoading = true
            this.$http.get('/feiyu/ip/list?' + tansParams(addDateRange(this.queryParams, this.timeRange)))
                .then(res => {
                    this.list = res.rows
                    this.total = res.total
                }).catch(err => {
                this.$message.error(err.msg)
            }).finally(() => {
                this.tableLoading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
