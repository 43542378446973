<template>
    <div>
        <div>
            <el-button type="primary" size="mini" @click="handleAddGroup">新增</el-button>
        </div>
        <el-table
            v-loading="loading"
            :data="list"
            border
            stripe
            max-height="500px"
            style="width: 100%;margin-top: 20px">
            <el-table-column
                prop="groupingName"
                align="center"
                label="分组名称"
                >
            </el-table-column>
            <el-table-column
                prop="createTime"
                align="center"
                label="创建时间">
            </el-table-column>
            <el-table-column
                align="center"
                width="80px"
                label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align: center;margin: 20px">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="changePage"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>

export default {
    name: 'IpGroup',
    data() {
        return {
            loading:false,
            show:false,
            timeRange: [],
            list: [],
            total: 0,
            queryParams: {
                pageSize:10,
                pageNum:1
            }
        };
    },
    components: {},
    created() {
        this.getGroupList()
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    methods: {
        handleAddGroup(){
            this.$prompt('请输入分组名称', '新增分组', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                this.addGroup(value)
            }).catch(() => {

            });
        },
        handleDelete(row){
            this.$http.post('/feiyu/ipGrouping/remove?id='+row.id).then(res => {
                this.$message.success('删除成功')
                this.getGroupList()
            }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        addGroup(groupName){
            this.$http.post('/feiyu/ipGrouping/add?groupingName='+groupName).then(res => {
                this.$message.success('添加成功')
                this.getGroupList()
            }).catch(err => {
                this.$message.error(err.msg)
            })
        },
        changePage(page){
            this.queryParams.pageNum = page
            this.getList()
        },
        getGroupList(){
            this.loading = true
            this.$http.get('/feiyu/ipGrouping/list')
                .then(res => {
                    this.list = res.rows
                    this.total = res.total
                }).catch(err => {
                this.$message.error(err.msg)
            }).finally(()=>{
                this.loading=false
            })
        },
    }
}
</script>

<style scoped>

</style>
