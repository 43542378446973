<template>
    <div>
        <h2>账号信息</h2>
        <el-row :gutter="12">
            <el-col :span="12">
                <el-card shadow="hover">
                    <div class="card-item">
                        <div>用户名</div>
                        <div style="margin: 10px 0;font-size: 20px;font-weight: bold">{{userInfo.phone}}</div>
                        <el-button size="mini" type="primary">修改密码</el-button>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card shadow="hover">
                    <div  class="card-item">
                        <div>账户余额（鱼币）</div>
                        <div style="margin: 10px 0;font-size: 20px;font-weight: bold">{{userInfo.balance}}</div>
                        <el-button size="mini" type="primary">充值</el-button>
                    </div>
                </el-card>
            </el-col>

        </el-row>
    </div>

</template>
<script>

export default {
    name: 'AccountInfo',
    data() {
        return {};
    },
    computed: {
        userInfo: function () {
            let userInfoStr = localStorage.getItem('userInfo');
            if (!userInfoStr) {
                return {}
            }
            return JSON.parse(userInfoStr)
        }
    },
    components: {},
    created() {
        console.log('accountInfo create...')
    },
    beforeDestroy() {
        console.log('accountInfo beforeDestroy...')
    },
    destroyed() {
        console.log('accountInfo destroyed...')
    },
    methods: {}
}
</script>

<style scoped>
.card-item{
    padding: 30px;
    text-align: center;
}
</style>
