<template>
    <div style="overflow-x: hidden">
        <el-row :gutter="0">
            <el-col :xs="10" :sm="6" :md="5" :lg="4" :xl="3">
                <el-card class="box-card-left">
                    <div class="info">
                        <el-avatar size="large" icon="el-icon-user-solid" src="circleUrl"></el-avatar>
                        <div style="margin-top: 10px">{{userInfo.phone}}</div>
                    </div>
                    <div style="height: 30px"></div>
                    <el-menu
                        default-active="accountInfo"
                        @select="menuSelect"
                        class="el-menu-vertical-demo">
                        <el-menu-item index="accountInfo" :route="{name:'accountInfo'}">
                            <i class="el-icon-user"></i>
                            <span slot="title">账号管理</span>
                        </el-menu-item>
                        <el-menu-item index="rechargeRecord" >
                            <i class="el-icon-s-order"></i>
                            <span slot="title">订单记录</span>
                        </el-menu-item>
                        <el-menu-item index="ipList" >
                            <i class="el-icon-s-management"></i>
                            <span slot="title">IP管理</span>
                        </el-menu-item>
                        <el-menu-item index="4">
                            <i class="el-icon-s-check"></i>
                            <span slot="title">实名认证</span>
                        </el-menu-item>
                        <el-menu-item index="5">
                            <i class="el-icon-s-comment"></i>
                            <span slot="title">反馈中心</span>
                        </el-menu-item>
                    </el-menu>
                </el-card>
            </el-col>
            <el-col :xs="14" :sm="18" :md="19" :lg="20" :xl="21">
                <el-card class="box-card-main">
                    <AccountInfo v-if="currentMenu==='accountInfo'"></AccountInfo>
                    <RechargeRecord v-if="currentMenu==='rechargeRecord'"></RechargeRecord>
                    <IpList v-if="currentMenu==='ipList'"></IpList>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>

import AccountInfo from "./AccountInfo.vue";
import RechargeRecord from "./RechargeRecord.vue";
import IpList from "@/components/IpList.vue";

export default {
    name: 'UserCenter',
    components: {IpList, RechargeRecord, AccountInfo},
    data() {
        return {
            currentMenu:'accountInfo'
        };
    },
    computed: {
        userInfo: function () {
            let userInfoStr = localStorage.getItem('userInfo');
            if (!userInfoStr) {
                return {}
            }
            return JSON.parse(userInfoStr)
        }
    },
    methods: {
        menuSelect(index){
            this.currentMenu = index
        },
    }
}
</script>

<style scoped>
.el-menu{
    border-right: none;
}
.box-card-left{
    margin: 10px;
    min-height: 700px;
}
.box-card-main{
    margin: 10px;
}
.info{
    text-align: center;
    font-size: 18px;
}
</style>
