<template>
    <div>
        <el-form :inline="true" size="mini" :model="queryParams" class="demo-form-inline">
            <el-form-item label="订单时间">
                <el-date-picker
                    v-model="timeRange"
                    type="datetimerange"
                    range-separator="至"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="loading" @click="getList">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table
            v-loading="loading"
            :data="list"
            border
            stripe
            max-height="800px"
            style="width: 100%;">
            <el-table-column
                prop="orderNo"
                width="200px"
                align="center"
                label="订单号"
                >
            </el-table-column>
            <el-table-column
                prop="goodsName"
                width="200px"
                align="left"
                label="商品名称">
            </el-table-column>
            <el-table-column
                prop="orderPrice"
                width="110px"
                align="right"
                label="订单金额">
            </el-table-column>
            <el-table-column
                prop="giveAmt"
                width="110px"
                align="right"
                label="赠送金额">
            </el-table-column>
            <el-table-column
                prop="orderType"
                width="110px"
                align="center"
                label="订单类型">
                <template slot-scope="scope">
                    <div>{{orderTypeFormat(scope.row.orderType)}}</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="orderStatus"
                width="110px"
                align="center"
                label="支付状态">
                <template slot-scope="scope">
                    <div v-html="orderStatusFormat(scope.row.orderStatus)"></div>
                </template>
            </el-table-column>
            <el-table-column
                prop="tradeType"
                width="110px"
                align="center"
                label="支付类型">
                <template slot-scope="scope">
                    <div v-html="tradeTypeFormat(scope.row.tradeType)"></div>
                </template>
            </el-table-column>
            <el-table-column
                prop="createTime"
                width="200px"
                align="center"
                label="订单时间">
            </el-table-column>
            <el-table-column
                prop="payTime"
                width="200px"
                align="center"
                label="付款时间">
            </el-table-column>
        </el-table>
        <div style="text-align: center;margin: 20px">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="changePage"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>

import {addDateRange, orderStatusFormat, orderTypeFormat, tansParams, tradeTypeFormat} from "@/api/utils";

export default {
    name: 'RechargeRecord',
    data() {
        return {
            loading:false,
            timeRange: [],
            list: [],
            total: 0,
            queryParams: {
                pageSize:10,
                pageNum:1,
                beginTime:'',
                endTime:'',
            }
        };
    },
    components: {},
    created() {
        console.log('accountInfo create...')
        this.getList()
    },
    beforeDestroy() {
        console.log('accountInfo beforeDestroy...')
    },
    destroyed() {
        console.log('accountInfo destroyed...')
    },
    methods: {
        orderTypeFormat,
        tradeTypeFormat,
        orderStatusFormat,
        onSubmit() {
        },
        changePage(page){
            this.queryParams.pageNum = page
            this.getList()
        },
        getList() {
            this.loading=true
            this.$http.post('/feiyu/order/list?' + tansParams(addDateRange(this.queryParams, this.timeRange)))
                .then(res => {
                    this.list = res.rows
                    this.total = res.total
                }).catch(err => {
                this.$message.error(err.msg)
            }).finally(()=>{
                this.loading=false
            })
        }
    }
}
</script>

<style scoped>

</style>
