<template>
    <div>

        <div style="margin: 0 180px;padding: 20px 0;color: #C0C4CC;font-size: 14px">
            <el-row :gutter="16">
                <el-col :span="18">
                    <div><i class="el-icon-phone"></i>客服热线：400-000-0000</div>
                    <br>
                    <div><i class="el-icon-location-information"></i>公司地址：厦门市思明区文屏路1-8号嘉禾良库文化创意园8号楼3Y27</div>
                    <br>
                    <div>
                        免责声明：<br>
                        飞鱼云仅提供IP服务，用户使用飞鱼云服务从事的任何行为，均不代表飞鱼云服务的意志和观点且与飞鱼云立场无关；<br>
                        严禁用户使用，飞鱼云服务从事任何违法犯罪行为，由此产生相关责任由当事用户自负，飞鱼云服务不承担任何法律责任。
                    </div>
                </el-col>
                <el-col :span="6">
                    <div>
                        厦门卡社诺科技有限公司 <br>
                        闽ICP备2024048887号-1 <br>
                        增值电信业务许可证：闽B2-20220177<br>
<!--                        互联网虚拟专用许可证：xx<br>-->
                        EDI在线数据处理与交易处理业务许可证：闽B2-20220177<br><br>
<!--                        <div style="display: flex;flex-direction: row">
                            <el-image :src="wa" style="width: 16px;height: 18px"></el-image>
                            <span style="margin-left: 8px">闽公网安备35018302800131</span>
                        </div>-->
                    </div>
                </el-col>
            </el-row>
        </div>
        <div style="text-align: center;color: #C0C4CC;font-size: 14px">
            Copyright © xx有限公司版权所有
        </div>

    </div>

</template>

<script>
import wa from '../assets/icon/wn.png'
export default {
    name: 'Footer',
    data() {
        return {
            wa
        };
    },
    methods: {

    }
}
</script>


<style scoped>

</style>
